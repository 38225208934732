import styled from "styled-components";
import { theme } from "helpers/constants";

export const HopleisureLink = styled.a`
  color: #2ca58d;
  text-decoration: underline;
  cursor: pointer;
`;

export const HopleisureTextButton = styled.div`
  color: #2ca58d;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
`;

export const Text = styled.p<{
  size?: string;
  margin?: string;
  weight?: number;
}>`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-align: start;
  color: #1e1e1e;
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

export const SecondaryText = styled.p<{ size?: string; margin?: string }>`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: 400;
  text-align: start;
  color: ${theme.color.primary};
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

export const AuthTitle = styled.h1`
  font-size: 50px;
  font-weight: 800;
`;

export const MainTitleText = styled.h1<{ align?: "start" | "center" | "end" }>`
  text-align: center;
  font-size: 25px;
  margin: 0;
  ${(props) => (props.align ? `text-align: ${props.align};` : "center")}
`;

export const SecondaryTitleText = styled.h2<{
  align?: "start" | "center" | "end";
}>`
  text-align: center;
  font-size: 24px;
  margin: 10px;
  ${(props) => (props.align ? `text-align: ${props.align};` : "center")}
`;

export const ThirdTitleText = styled.h3<{ align?: "start" | "center" | "end" }>`
  text-align: center;
  font-size: 24px;
  margin: 10px;
  color: #2ca58d;
  ${(props) => (props.align ? `text-align: ${props.align};` : "center")}
`;

export const FourthTitleText = styled.h4<{
  align?: "start" | "center" | "end";
}>`
  text-align: center;
  font-size: 20px;
  margin: 10px;
  color: #004643;
  ${(props) => (props.align ? `text-align: ${props.align};` : "center")}
`;

export const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  gap: 30px;
  padding: 30px;
`;

export const MainPageCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

export const FormBox = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`;

export const FormLabel = styled.div`
  flex: 1;
  font-weight: 700;
  min-width: 150px;
`;

export const FormErrorHelper = styled.div`
  color: red;
  font-size: 0.8571428571428571rem;
`;

export const GreenBoxWithTitle = styled.div`
  color: white;
  background-color: #2ca58d;
  font-weight: 800;
  font-size: 24px;
  width: 432px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "start",
      "end",
      "center",
      "space",
      "alignStart",
      "alignCenter",
      "alignSpace",
      "margin",
      "width",
      "alignEnd",
    ].includes(prop),
})<{
  start?: boolean;
  end?: boolean;
  center?: boolean;
  space?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  alignCenter?: boolean;
  alignSpace?: boolean;
  margin?: string;
  width?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
        ? "flex-end"
        : props.center
          ? "center"
          : props.space
            ? "space-between"
            : "flex-start"};
  align-items: ${(props) =>
    props.alignStart
      ? "flex-start"
      : props.alignEnd
        ? "flex-end"
        : props.alignCenter
          ? "center"
          : props.alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => (props.width ? props.width : "auto")};
`;

export const Column = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "start",
      "end",
      "center",
      "space",
      "alignStart",
      "alignCenter",
      "alignSpace",
      "margin",
      "width",
    ].includes(prop),
})<{
  start?: boolean;
  end?: boolean;
  center?: boolean;
  space?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  alignCenter?: boolean;
  alignSpace?: boolean;
  margin?: string;
  width?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
        ? "flex-end"
        : props.center
          ? "center"
          : props.space
            ? "space-between"
            : "flex-start"};
  align-items: ${(props) =>
    props.alignStart
      ? "flex-start"
      : props.alignEnd
        ? "flex-end"
        : props.alignCenter
          ? "center"
          : props.alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => (props.width ? props.width : "auto")};
`;

export const ModalStyle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: white;
  box-shadow: 24;
  padding: 50px;
  border-radius: 20px;
`;
