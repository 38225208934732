import React, { useEffect, useState } from "react";
import "./WidgetTab.css";
import SelectActivities from "components/discounts/SelectActivities";
import { useMutation, useQuery } from "react-query";
import { Activity, getActivities } from "backend/api/activities";
import { SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputText, Select } from "components/formInputs/Inputs";
import { useForm, Controller } from "react-hook-form";
import ColorSelector from "components/colorSelector/ColorSelector";
import { SelectOption, widgetSchema, WidgetType } from "./WidgetTab.types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  defaultWidgetColors,
  initialWidget,
  maxExtraInfos,
} from "./WidgetTab.constants";
import { isArray } from "lodash";
import {
  arePropsEqual,
  copyToClipboard,
  isArrayEmpty,
  toastEmitter,
} from "helpers/helpers";
import {
  filterActivitiesForWidget,
  formatActivitiesIds,
  formatFontFamiliesOptions,
  getGoogleTopFontFamilies,
  getIframeCode,
  isWidgetNameAlreadyUsed,
} from "./WidgetTab.utils";
import WidgetCard from "components/widgetCard/WidgetCard";
import { getWidgets, upsertWidget } from "backend/api/widgets";
import Loader from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { WIDGETS_UPLOAD_SUCCESS } from "constants/widgetConstants";
import { RootState } from "store";
import SaveFormModal from "./saveFormModal/SaveFormModal";
import { useNavigationBlocker } from "./useNavigationBlocker";

const WidgetTab = () => {
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]);
  const [activeWidgetId, setActiveWidgetId] = useState<number | null>(null);
  const [fontFamiliesOptions, setFontFamiliesOptions] = useState<
    SelectOption[]
  >([]);
  const [iframeCode, setIframeCode] = useState("");
  const [isSaveFormModalOpen, setIsSaveFormModalOpen] = useState(false);
  const [saveDataChoice, setSaveDataChoice] = useState({
    save: false,
    quit: false,
    edit: false,
  });
  const { t } = useTranslation("website");
  const dispacth = useDispatch();
  const { navigationBlocker, setBlockCondition } = useNavigationBlocker();
  const widgets = useSelector(
    (state: RootState) => state?.widgets,
  ) as WidgetType[];

  const { data: activities } = useQuery({
    queryKey: ["getActivities"],
    queryFn: () => getActivities(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setFilteredActivities(filterActivitiesForWidget(data));
    },
  });

  const { refetch: refetchWidgets, isLoading: widgetsLoading } = useQuery({
    queryKey: ["getWidgets"],
    queryFn: () => getWidgets(),
    onSuccess(data) {
      dispacth({ type: WIDGETS_UPLOAD_SUCCESS, payload: data });
    },
    refetchOnWindowFocus: false,
  });

  const defaultValues = !isArrayEmpty(widgets) ? initialWidget : widgets[0];
  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>(
    formatActivitiesIds(defaultValues),
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: hasUnsavedChanges },
    setValue,
    getValues,
    watch,
    reset,
    setError,
  } = useForm<WidgetType>({
    resolver: zodResolver(widgetSchema(t)),
    defaultValues,
  });

  useEffect(() => {
    setBlockCondition(hasUnsavedChanges);
    if (navigationBlocker.state === "blocked") {
      setIsSaveFormModalOpen(true);
    }
  }, [navigationBlocker, hasUnsavedChanges]);

  useEffect(() => {
    //condition repeated to avoid typescript compile error
    if (navigationBlocker.state === "blocked") {
      const { quit, save } = saveDataChoice;
      if (quit) {
        navigationBlocker.proceed();
      } else if (save) {
        const formData = getValues();
        onSubmit(formData);
        navigationBlocker.proceed();
      }
    }
  }, [saveDataChoice, navigationBlocker]);

  useEffect(() => {
    if (widgets && !isArrayEmpty(widgets)) {
      const activeWidget =
        widgets.find(
          (widget: WidgetType) =>
            widget.id === activeWidgetId && !widget.isDeleted,
        ) ||
        widgets.find((widget: WidgetType) => !widget.isDeleted) ||
        initialWidget;

      if (activeWidget) {
        reset(activeWidget);
        setSelectedActivityIds(formatActivitiesIds(activeWidget));
      }
    }
  }, [widgets, reset, activeWidgetId]);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedActivityIds(
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const { mutate, isLoading: isWidgetUpdating } = useMutation({
    mutationFn: async (data: WidgetType) => {
      return await upsertWidget(data);
    },
    onSuccess: (result: any, widgetData: WidgetType) => {
      const isWidgetNew = !Boolean(widgetData.id);
      setActiveWidgetId(result.data?.id);

      const toastMsg = isWidgetNew
        ? `${result.data.name} ${t("createWidgetSuccessfully")}`
        : `${result.data.name} ${t("updateWidgetSuccessfully")}`;

      toastEmitter(toastMsg, "success");
      refetchWidgets();
    },
    onError: (error: any) => {
      console.error(error);
      toastEmitter(`${t("errors.updateWidget")} ${error.message}`, "error");
    },
  });

  const onSubmit = (data: WidgetType) => {
    if (isWidgetNameAlreadyUsed(data.name, widgets) && !widgetId) {
      // Set a custom error for the name field
      setError("name", {
        type: "manual",
        message: t("errors.widgetNameAlreadyUsed"),
      });
    } else mutate(data);
  };

  const extraInfos = watch("extraInfos");
  const widgetId = watch("id");

  useEffect(() => {
    setValue(
      "activitiesIds",
      selectedActivityIds.map((id) => Number(id)),
    );
  }, [selectedActivityIds, setValue]);

  useEffect(() => {
    const fetchFonts = async () => {
      const googleFonts = await getGoogleTopFontFamilies();
      const formattedFonts = formatFontFamiliesOptions(googleFonts);
      setFontFamiliesOptions(formattedFonts);
    };

    fetchFonts();
  }, []);

  useEffect(() => {
    setIframeCode(getIframeCode(widgetId));
  }, [widgetId, setIframeCode]);

  const changeWidgetForm = (widget: WidgetType) => {
    setSelectedActivityIds(formatActivitiesIds(widget));
    reset(widget);
  };

  const addNewWidget = () => {
    changeWidgetForm(initialWidget);
  };

  const handleAddInfo = () => {
    if (isArray(extraInfos)) {
      if (extraInfos.length < maxExtraInfos) {
        const updatedExtraInfos = [...extraInfos, ""];
        setValue("extraInfos", updatedExtraInfos);
      } else {
        toastEmitter(t("errors.maxExtraInfos"), "error");
      }
    }
  };
  const RemoveAddInfo = (index: number) => {
    if (isArray(extraInfos)) {
      const updatedExtraInfos = [
        ...extraInfos.slice(0, index),
        ...extraInfos.slice(index + 1),
      ];
      setValue("extraInfos", updatedExtraInfos); // Update the form value
    }
  };
  const copyIframeCode = async () => {
    try {
      await copyToClipboard(iframeCode);
      toastEmitter(t("copyIframeCodeSuccessMsg"), "success");
    } catch (error) {
      toastEmitter(t("errors.copyIframeCodeErrorMsg"), "error");
    }
  };

  return (
    <>
      {isWidgetUpdating || widgetsLoading ? (
        <Loader />
      ) : (
        <div className="widget-tab">
          <div className="section widget-defintion">
            <span className="section-title">{t("widgetsSectionTitle")}</span>
            <div className="saved-widgets-container">
              {!isArrayEmpty(widgets) && (
                <>
                  {widgets?.map((widget: WidgetType) => (
                    <React.Fragment key={widget.id}>
                      {!widget.isDeleted && (
                        <WidgetCard
                          key={widget.id}
                          widget={widget}
                          changeWidgetForm={changeWidgetForm}
                          isActive={widget.id === widgetId}
                          refetchWidgets={refetchWidgets}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              <AddCircleIcon
                style={{
                  fill: "#4FD1C5",
                  cursor: "pointer",
                }}
                onClick={addNewWidget}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="widget-form">
              <InputText
                label={t("widgetNameLabel")}
                name="name"
                id="name"
                control={control}
                error={errors.name?.message}
                t={t}
              />
              {!isArrayEmpty(activities) && (
                <SelectActivities
                  selectedActivityIds={selectedActivityIds}
                  activities={filteredActivities}
                  handleSelectChange={handleSelectChange as any}
                  t={t}
                  label={t("activitiesSelectorLabel")}
                  errors={errors.activitiesIds}
                />
              )}
              <button className="standard-btn" type="submit">
                {t("widgetCreationBtn")}
              </button>
            </form>
            <div className="iframe-section">
              <span className="section-subtitle">{t("iframeCodeLabel")}</span>
              <span className="iframe-code">{iframeCode}</span>
              <button
                className="iframe-copy-btn"
                onClick={copyIframeCode}
                disabled={!Boolean(iframeCode.length)}
              >
                {t("codeCopyBtn")}
              </button>
            </div>
          </div>
          <form
            className="section widget-config"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="widget-style">
              <span className="section-title">
                {t("iframeStyleSectionTitle")}
              </span>
              <span className="section-subtitle">
                {t("iframeStyleSectionSubtitle")}
              </span>

              {!isArrayEmpty(fontFamiliesOptions) && (
                <Select
                  label={t("widgetFontFamily")}
                  name="style.fontFamily"
                  id="style.fontFamily"
                  control={control}
                  t={t}
                  options={fontFamiliesOptions}
                  error={errors.style?.fontFamily}
                />
              )}
              {Object.keys(defaultWidgetColors).map((key, index) => (
                <Controller
                  key={index}
                  name={`style.colors.${key}` as any}
                  control={control}
                  render={({ field }) => (
                    <>
                      {field.value && (
                        <ColorSelector
                          key={index}
                          initialColor={field.value as string}
                          label={t(`widgetColors.${key}`)}
                          updateWidgetColor={(color: string) =>
                            field.onChange(color)
                          }
                        />
                      )}
                    </>
                  )}
                />
              ))}
            </div>
            <div className="extra-infos-section">
              <span className="section-subtitle">{t("infosSectionTitle")}</span>
              <span>{t("infosSectionText")}</span>
              <div className="horizontal-info-group">
                <AddCircleOutlineIcon
                  style={{ color: "#4FD1C5", cursor: "pointer" }}
                  onClick={handleAddInfo}
                />
                <span>{t("addInfoBtnLabel")}</span>
              </div>
              <div>
                {extraInfos?.map((field, index) => (
                  <div key={index} className="horizontal-info-group">
                    <InputText
                      control={control}
                      name={`extraInfos[${index}]`}
                      id={`extraInfos[${index}]`}
                      label={`${t("extraInfoInputLabel")} ${index + 1}`}
                      t={t}
                      error={errors.extraInfos?.[index]?.message}
                    />
                    <RemoveCircleOutlineIcon
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        RemoveAddInfo(index);
                      }}
                    />
                  </div>
                ))}
              </div>
              <button className="standard-btn" type="submit">
                {t("saveDesignBtn")}
              </button>
            </div>
          </form>
          <SaveFormModal
            isSaveFormModalOpen={isSaveFormModalOpen}
            setIsSaveFormModalOpen={setIsSaveFormModalOpen}
            setSaveDataChoice={setSaveDataChoice}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(WidgetTab, arePropsEqual);
