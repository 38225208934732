import bookings from "./bookings";
import activities from "./activities";

const agenda: any = {
  en: {
    ...bookings.en,
    ...activities.en,
    agendaTitle: "Calendar",
    agendaHeader: {
      week: "Week",
      day: "Day",
      momentOfDay: "Moment of day",
      activities: "Activities",
    },
    agendaEvent: {
      full: "Full",
      limitedTo: "Limited to",
    },
    bookings: {
      addBookingTitle: "Add a booking",
      updateBookingTitle: "Update a booking",
      addBooking: "Add",
      updateBooking: "Update",
      cancelBooking: "Cancel booking",
    },
    updateBookingsModal: {
      myBookings: "My bookings",
      newBooking: "Add a new booking",
      noBookingsYetOnThisActivity: "No bookings yet",
    },
    confirmationModal: {
      addConfirmationModalTitle: "Confirm the booking ?",
      addConfirmationModalExplainations: "You will add a booking on this date",
      // #TODO: delete notPossibleUpdateForTheMomentConfirmationModalTitle and notPossibleUpdateForTheMomentModalExplainations
      // when we will finish update booking feature
      notPossibleUpdateForTheMomentConfirmationModalTitle:
        "We are not allow bookings update for the moment",
      notPossibleUpdateForTheMomentModalExplainations:
        "To update your booking, please cancel this one and do another one",
      updateConfirmationModalTitle: "Update the booking ?",
      updateConfirmationModalExplainations:
        "You will update the booking on that date. Your customer will be notified",
      cancelConfirmationModalTitle:
        "Be careful, you are about to cancel a booking",
      cancelConfirmationModalExplainations: `
                You are about to cancel a reservation, 
                if you cancel it, the customer will be notified. Avoid canceling reservations except in an emergency.
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromWidget: `
                The reservation has already been paid by the client, so you will need to make a refund from your Stripe dashboard if you cancel it.
                Make sure you know how to make a refund from Stripe, also ensure you find the correct payment and proceed with the refund.
                Find the payment in your stripe dashboard by finding the one with this email {{ email }} and this orderId {{ orderId }}.
                If you have any doubts, contact Hopleisure, and we will help you to make the refund.
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromHopleisure: `
                You said that the client had already paid, be sure you refund the client before proceed to cancellation.
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromAPlatform: `
                We will manage the refund of your client with {{ platformName }}.
            `,
      bookingPaidTitle: "Is the booking has been paid ?",
    },
    changeBookingStatusModal: {
      confirmCheckIn: "Confirm check-in ?",
      beCarefulConfirmation: "Please note, confirm is irreversible.",
    },
    changeSlotStatusModal: {
      closeThisSlotToBookings: "Close or stop taking bookings on the slot",
      closeThisSlotToBookingsExplainations:
        "If you close the slot, all your bookings on it will be canceled.",
      doNotTakeMoreBookings: "Stop taking bookings on this slot",
      closeTheSlotToBookings: "Close the slot to bookings",
      reopenThisSlotToBookings: "Reopen the slot to bookings",
      reopenThisSlotToBookingsExplainations:
        "If you reopen the slot, the canceled bookings on it will stay canceled",
      retakeBookings: "Retake bookings",
      reopenTheSlotToBookings: "Reopen the slot to bookings",
      cancelAndCloseConfirmationModalTitle:
        "Close the slot and cancel bookings ?",
      cancelAndCloseConfirmationModalExplainations:
        "You are about to cancel a slot, if you close the slot, all your bookings on it will be canceled and the customers notified. Avoid canceling reservations except in an emergency.",
      cancelAndCloseConfirmationModalExplainations2:
        "Be sure you follow the instructions in the canceled booking cards of this slot after have clicked on Confirm",
      reopenConfirmationModalTitle: "Reopen the slot to bookings ?",
      reopenConfirmationModalExplainations:
        "If you reopen the slot, the canceled bookings on it will stay canceled",
    },
    visitorInformations: "Visitor informations",
    success: {
      addBookingMessage: "Booking well added",
      updateBookingMessage: "Booking well updated",
      addSpecialSlotMessage: "Slot updated",
      updateBookingStatusMessage: "Booking status updated",
    },
    errors: {
      MARK_AS_FULL:
        "The slot is marked as full, please retrieve this limitation and retry",
      UNAVAILABLE: "The slot is marked as canceled, reopen it and retry",
      NOT_AVAILABLE_SLOT: "This slot is no longer available",
      NOT_ENOUGHT_PLACES_ON_SLOT:
        "There is not enough places on the slot, retry on another slot or decrease the number of people",
      DATE_IN_THE_PAST:
        "The begin date of the slot is in the past, retry with a valid slot",
      NOT_FOUND: "Data not found",
      INTERNAL_SERVOR_ERROR: "Internal server error, please contact us",
    },
    morning: "Morning",
    afternoon: "Afternoon",
    evening: "Evening",
    noShow: "No show",
    confirm: "Confirm",
    cancel: "Cancel",
    back: "Back",
    no: "No",
    yes: "Yes",
    toRefundHopleisure: "To refund by your own",
    toRefundWidget: "To refund on stripe, orderId: {{orderId}}",
    toRefundPlatform:
      "Customer notified. Please proceed with cancellation on {{platformName}}",
  },
  fr: {
    ...bookings.fr,
    ...activities.fr,
    agendaTitle: "Agenda",
    visitorInformations: "Informations du visiteur",
    agendaHeader: {
      week: "Semaine",
      day: "Jour",
      momentOfDay: "Moment de la journée",
      activities: "Activités",
    },
    agendaEvent: {
      full: "Complet",
      limitedTo: "Limité à",
    },
    bookings: {
      addBookingTitle: "Ajouter une réservation",
      updateBookingTitle: "Modifier une réservation",
      addBooking: "Ajouter",
      updateBooking: "Modifier",
      cancelBooking: "Annuler la réservation",
    },
    updateBookingsModal: {
      myBookings: "Mes réservations",
      newBooking: "Ajouter une réservation",
      noBookingsYetOnThisActivity: "Pas encore de réservation sur ce créneau",
    },
    confirmationModal: {
      addConfirmationModalTitle: "Valider la réservation ?",
      addConfirmationModalExplainations:
        "Vous ajouterez une réservation sur cette date",
      // #TODO: delete notPossibleUpdateForTheMomentConfirmationModalTitle and notPossibleUpdateForTheMomentModalExplainations
      // when we will finish update booking feature
      notPossibleUpdateForTheMomentConfirmationModalTitle:
        "Les réservations ne sont pas modifiables pour le moment",
      notPossibleUpdateForTheMomentModalExplainations:
        "Pour changer une réservation, veuillez annuler celle-ci et en faire une autre",
      updateConfirmationModalTitle: "Modifier la réservation ?",
      updateConfirmationModalExplainations:
        "Vous modifierez la réservation sur cette date, votre client en sera notifié",
      cancelConfirmationModalTitle:
        "Attention vous allez annuler une réservation",
      cancelConfirmationModalExplainations: `
                Vous vous apprêtez à annuler une réservation, si vous l'annulez le client en sera notifié. 
                Évitez d'annuler des réservations sauf en cas d'urgence.
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromWidget: `
                La réservation a déjà été payée par le client sur votre site internet.<br/>
                Veuillez effectuer le remboursement depuis votre tableau de bord Stripe accessible dans votre onglet <bold>“Mon profil > Facturation”</bold>.<br/>
                Informations de la réservation à rembourser dans votre tableau de bord Stripe : <bold>OrderId : {{ orderId }}</bold> <br/>
                <bold>Email</bold>: equipe.commerciale@hopleisure.com <br/>
                Voici un tutoriel vidéo pour vous aider à réaliser le remboursement: <urlLink>lien vidéo</urlLink>
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromHopleisure: `
                Vous avez indiqué avoir été payé par le client, veillez à le rembourser avant de procéder à l’annulation.
            `,
      cancelConfirmationModalExplainationsPaymentDoneFromAPlatform: `
                Votre client a déjà réglé sa réservation sur {{ platformName }}, <bold>veillez à le rembourser avant de procéder à l'annulation.<bold/>
            `,
      bookingPaidTitle: "La réservation a-t-elle été payé ?",
    },
    changeBookingStatusModal: {
      confirmCheckIn: "Confirmer le check-in ?",
      beCarefulConfirmation: "Attention, la validation est irréversible.",
    },
    changeSlotStatusModal: {
      closeThisSlotToBookings: "Fermer l'activité sur le créneau",
      closeThisSlotToBookingsExplainations:
        "S'il existe des réservations sur ce créneau, elles seront annulées et les clients notifiés. Évitez d'annuler des créneaux avec des réservations sauf en cas d'urgence..",
      doNotTakeMoreBookings: "Ne plus prendre de réservations",
      closeTheSlotToBookings: "Fermer le créneau aux réservations",
      reopenThisSlotToBookings: "Ré-ouvrir le créneau aux réservations",
      reopenThisSlotToBookingsExplainations:
        "Si vous réouvrez le créneau, les réservations précédemment annulées le resteront",
      retakeBookings: "Reprendre les réservations",
      reopenTheSlotToBookings: "Réouvrir le créneau",
      cancelAndCloseConfirmationModalTitle:
        "Fermer le créneau aux réservations ?",
      cancelAndCloseConfirmationModalExplainations:
        "S'il existe des réservations sur ce créneau, elles seront annulées et les clients notifiés. Évitez d'annuler des créneaux avec des réservations sauf en cas d'urgence..",
      cancelAndCloseConfirmationModalExplainations2:
        "Veillez à bien suivre les instructions liées aux remboursements qui seront indiquées dans vos réservations annulées sur ce créneau après avoir cliqué sur valider",
      reopenConfirmationModalTitle: "Ré-ouvrir le créneau aux réservations ?",
      reopenConfirmationModalExplainations:
        "Si vous réouvrez le créneau, les réservations précédemment annulées le resteront",
    },
    success: {
      addBookingMessage: "Réservation ajoutée !",
      updateBookingMessage: "Réservation mise à jour !",
      addSpecialSlotMessage: "Créneau mis à jour !",
      updateBookingStatusMessage: "Statut de la réservation bien mis à jour !",
    },
    errors: {
      MARK_AS_FULL:
        "Le créneau est indiqué comme plein, veuillez enlever la restriction et réessayer",
      UNAVAILABLE:
        "Le créneau est indiqué comme fermé, veuillez le réouvrir et réessayer",
      NOT_AVAILABLE_SLOT: "Le créneau n'est pas disponible",
      NOT_ENOUGHT_PLACES_ON_SLOT:
        "Il n'y a plus assez de places sur le créneau",
      DATE_IN_THE_PAST:
        "La créneau indiqué a une date de début dans le passé, veuillez réessayer avec un créneau correct",
      NOT_FOUND: "Donnée non trouvée",
      INTERNAL_SERVOR_ERROR: "Erreur interne, veuillez nous contacter",
    },
    morning: "Matin",
    afternoon: "Après-midi",
    evening: "Soirée",
    noShow: "No show",
    confirm: "Valider",
    cancel: "Annuler",
    back: "Retour",
    no: "Non",
    yes: "Oui",
    toRefundHopleisure: "A rembourser par vos soins",
    toRefundWidget: "A rembourser par stripe, orderId: {{orderId}}",
    toRefundPlatform:
      "Client notifié. Merci de procéder à l'annulation sur {{platformName}}",
  },
};

export default agenda;
