import { Button } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import styled from "styled-components";
import Logo from "../../images/logo.png";
import { HopleisureTextButton } from "../../helpers/generalStyles";
import { useNavigate } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DiscountIcon from "@mui/icons-material/Discount";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LanguageIcon from "@mui/icons-material/Language";
import BuildIcon from "@mui/icons-material/Build";
import { useTranslation } from "react-i18next";
import { useUser } from "contexts/UserContext";

const drawerWidth = 264;

const menuInfos: any = {
  agenda: {
    title: "agenda",
    paths: ["/agenda"],
    disabled: false,
    whiteIcone: <TodayIcon style={{ color: "white" }} />,
    greenIcone: <TodayIcon style={{ color: "#4FD1C5" }} />,
  },
  bookings: {
    title: "bookings",
    paths: ["/bookings"],
    disabled: false,
    whiteIcone: <EventAvailableIcon style={{ color: "white" }} />,
    greenIcone: <EventAvailableIcon style={{ color: "#4FD1C5" }} />,
  },
  discounts: {
    title: "discounts",
    paths: ["/discounts"],
    disabled: false,
    whiteIcone: <DiscountIcon style={{ color: "white" }} />,
    greenIcone: <DiscountIcon style={{ color: "#4FD1C5" }} />,
  },
  dashboard: {
    title: "dashboard",
    paths: ["/dashboard"],
    disabled: true,
    whiteIcone: <SpaceDashboardIcon style={{ color: "white" }} />,
    greenIcone: <SpaceDashboardIcon style={{ color: "#4FD1C5" }} />,
  },
  activities: {
    title: "activities",
    paths: ["/activities"],
    disabled: false,
    whiteIcone: <RocketLaunchIcon style={{ color: "white" }} />,
    greenIcone: <RocketLaunchIcon style={{ color: "#4FD1C5" }} />,
  },
  profile: {
    title: "profile",
    paths: ["/profile", "/facturation"],
    disabled: false,
    whiteIcone: <AccountBoxIcon style={{ color: "white" }} />,
    greenIcone: <AccountBoxIcon style={{ color: "#4FD1C5" }} />,
  },
  website: {
    title: "website",
    paths: ["/website"],
    disabled: false,
    whiteIcone: <LanguageIcon style={{ color: "white" }} />,
    greenIcone: <LanguageIcon style={{ color: "#4FD1C5" }} />,
  },
  // tools: {
  //   title: "tools",
  //   paths: ["/tools"],
  //   disabled: true,
  //   whiteIcone: <BuildIcon style={{ color: "white" }} />,
  //   greenIcone: <BuildIcon style={{ color: "#4FD1C5" }} />,
  // },
};

export default function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { setUser } = useUser();
  const path = window.location.pathname;

  const handleDisconnect = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    setUser(undefined);
    setTimeout(() => {
      navigate("/login");
      window.location.reload();
    }, 300);
  };

  return (
    <Nav style={{ width: drawerWidth }}>
      <div>
        <NavHeader>
          <HopleisureLogoDiv>
            <img width="max-content" src={Logo} alt="logo" height="30px" />
          </HopleisureLogoDiv>
          <HopleisureSubLogoDiv>{t("introduceHopbooks")}</HopleisureSubLogoDiv>
          <Divider style={{ marginTop: "5px" }} />
        </NavHeader>
        <NavBody>
          {Object.keys(menuInfos).map((menuItem: any, index) => {
            const currentItem = menuInfos[menuItem];
            const isSelectedMenuItem = currentItem.paths.includes(path);
            return (
              <ListItem key={currentItem.title} disablePadding>
                <ListItemButton
                  style={{
                    color: isSelectedMenuItem ? "#4FD1C5" : "white",
                    backgroundColor: isSelectedMenuItem
                      ? "white"
                      : "transparent",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    !currentItem.disabled
                      ? navigate(currentItem.paths[0])
                      : console.log({ disabled: currentItem.disabled })
                  }
                >
                  <ListItemIcon>
                    {isSelectedMenuItem
                      ? currentItem.greenIcone
                      : currentItem.whiteIcone}
                  </ListItemIcon>
                  <ListItemText primary={t(currentItem.title)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </NavBody>
      </div>
      <NavFooter>
        <ContactBox>
          <ContactBoxHeader>
            <ContactSupportIcon style={{ color: "white" }} />
            <div>{t("needAssistance")}</div>
          </ContactBoxHeader>
          <ContactBoxText>{t("needAssistanceText")}</ContactBoxText>
          <Button
            variant="contained"
            type="button"
            onClick={() =>
              (window.location.href =
                "mailto:equipe.commerciale@hopleisure.com")
            }
          >
            {t("needAssistanceButton")}
          </Button>
        </ContactBox>
        <HopleisureTextButton
          onClick={handleDisconnect}
          style={{
            color: "white",
            width: "100%",
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {t("logout")}
        </HopleisureTextButton>
      </NavFooter>
    </Nav>
  );
}

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #054643;
  color: white;
  border-radius: 0px 20px 20px 0px;
  min-height: 100vh;
  height: 100%;
`;

const NavHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    #004643 0%,
    #e0e1e2 49.52%,
    rgba(224, 225, 226, 0.15625) 99.04%
  );
`;

const HopleisureLogoDiv = styled.div`
  max-width: 100%;
  margin-bottom: 10px;
  text-align: center !important;
`;

const HopleisureSubLogoDiv = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
`;

const NavBody = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const NavFooter = styled.div``;

const ContactBox = styled.div`
  background-color: #4fd1c5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 232px;
  border-radius: 15px;
  margin: 5px;
  gap: 5px;
  padding: 10px;
`;
const ContactBoxHeader = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 800;
  gap: 10px;
`;

const ContactBoxText = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 13px;
`;
