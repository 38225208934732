import SpaIcon from "@mui/icons-material/Spa";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import SailingIcon from "@mui/icons-material/Sailing";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ForestIcon from "@mui/icons-material/Forest";
import HikingIcon from "@mui/icons-material/Hiking";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import MapIcon from "@mui/icons-material/Map";
import ParaglidingOutlinedIcon from "@mui/icons-material/ParaglidingOutlined";
import RollerSkatingIcon from "@mui/icons-material/RollerSkating";
import DiamondIcon from "@mui/icons-material/Diamond";
import CookieIcon from "@mui/icons-material/Cookie";
import MuseumIcon from "@mui/icons-material/Museum";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import TargetIcon from "images/icons/TargetIcon";
import BowlingIcon from "images/icons/BowlingIcon";

export const theme = {
  color: {
    primary: "#064643",
    secondary: "#4fd1c5",
  },
};

// /!\ When adding a new category, associate it a logo in categoryImageMapping
export const activityCategories: any = {
  ARCADE: "arcade",
  BOWLING: "bowling",
  LASER_GAME: "laserGame",
  PAINTBALL: "paintBall",
  ESCAPE_GAME: "escapeGame",
  SPA: "spa",
  WELL_BEING: "wellBeing",
  VR_SIMULATION: "vrSimulation",
  KARTING: "karting",
  CRUISES: "cruises",
  INDOOR_GAMES: "indoorGames",
  ACCROBRANCHE: "accrobranche",
  HIKING_NATURE: "hikingNature",
  OUTDOOR_GAMES: "outdoorGames",
  GUIDED_TOUR: "guidedTour",
  EXTREM_SPORT: "extremSport",
  BOAT_TRIP: "boatTrip",
  PARACHUTE_JUMP: "parachuteJump",
  CREATIVE_WORKSHOP: "creativeWorkshop",
  COOKING_AND_LESSONS: "cookingAndLessons",
  MUSEUMS_AND_EXHIBITIONS: "museumsAndExhibitions",
  SPORT_AND_FITNESS: "sportAndFitness",
};

export const categoryImageMapping = (category: string, color?: string) => {
  const categoryImageMapping: { [key: string]: any } = {
    ARCADE: <AlternateEmailIcon style={{ color }} />,
    BOWLING: <BowlingIcon color={color} />,
    LASER_GAME: <TargetIcon color={color} />,
    PAINTBALL: <TargetIcon color={color} />,
    ESCAPE_GAME: <ZoomInIcon style={{ color }} />,
    SPA: <SpaIcon style={{ color }} />,
    WELL_BEING: <SelfImprovementIcon style={{ color }} fontSize="small" />,
    VR_SIMULATION: <VideogameAssetIcon style={{ color }} fontSize="small" />,
    KARTING: <DirectionsCarOutlinedIcon style={{ color }} fontSize="small" />,
    CRUISES: <SailingIcon style={{ color }} fontSize="small" />,
    INDOOR_GAMES: <SmartToyIcon style={{ color }} fontSize="small" />,
    ACCROBRANCHE: <ForestIcon style={{ color }} fontSize="small" />,
    HIKING_NATURE: <HikingIcon style={{ color }} fontSize="small" />,
    OUTDOOR_GAMES: <NaturePeopleIcon style={{ color }} fontSize="small" />,
    GUIDED_TOUR: <MapIcon style={{ color }} fontSize="small" />,
    EXTREM_SPORT: <RollerSkatingIcon style={{ color }} fontSize="small" />,
    BOAT_TRIP: <SailingIcon style={{ color }} fontSize="small" />,
    PARACHUTE_JUMP: (
      <ParaglidingOutlinedIcon style={{ color }} fontSize="small" />
    ),
    CREATIVE_WORKSHOP: <DiamondIcon style={{ color }} fontSize="small" />,
    COOKING_AND_LESSONS: <CookieIcon style={{ color }} fontSize="small" />,
    MUSEUMS_AND_EXHIBITIONS: <MuseumIcon style={{ color }} fontSize="small" />,
    SPORT_AND_FITNESS: <FitnessCenterIcon style={{ color }} fontSize="small" />,
  };
  //delete error messages coming from importing component from iconscout/react-unicons package
  // const originalConsoleError = console.error;
  // console.error = (message, ...args) => {
  //   if (
  //     !message.includes(
  //       "Support for defaultProps will be removed from function components",
  //     )
  //   ) {
  //     originalConsoleError(message, ...args);
  //   }
  // };
  return categoryImageMapping[category];
};

export const activitySlotTypes = {
  "30_MINUTES": "30 Minutes",
  "60_MINUTES": "60 Minutes",
  "90_MINUTES": "90 Minutes",
};

// Enums
export enum BookingStatusEnum {
  BOOKED = "BOOKED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  NO_SHOW = "NO_SHOW",
  DONE = "DONE",
}

export enum SpecialSlotTypeEnum {
  MARK_AS_FULL = "MARK_AS_FULL",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum PlatformTypeEnum {
  GENERAL = "GENERAL",
  SPECIFIC = "SPECIFIC",
  CSE = "CSE",
  HOPLEISURE = "HOPLEISURE",
  WIDGET = "WIDGET",
}

export enum DiscountTypesEnum {
  PERCENTAGE = "PERCENTAGE",
  SUBSTRACTION = "SUBSTRACTION",
}

export enum ActivityPricesStatusEnum {
  ENABLED = "ENABLED",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
}

export enum ActivityPricesTypeEnum {
  DEFAULT = "DEFAULT",
  SENIOR = "SENIOR",
  CHILD = "CHILD",
  GROUP = "GROUP",
}

export enum ActivityPlatformStatusEnum {
  OPENED = "OPENED",
  PENDING = "PENDING",
  CLOSED = "CLOSED",
}

export enum ActivityTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  GROUP = "GROUP",
}

export const emptyActivity: any = {
  informations: {
    description: null,
    slotDuration: 0,
    images: [],
    email: null,
    name: null,
    address: null,
    timezone: {},
    website: "",
    phone: null,
    cancelation: null,
    category: "ARCADE",
    isEnabled: false,
    bookingSettings: {
      type: null,
    },
  },
  hours: {
    hours: {
      "0": {
        slots: [],
      },
      "1": {
        slots: [],
      },
      "2": {
        slots: [],
      },
      "3": {
        slots: [],
      },
      "4": {
        slots: [],
      },
      "5": {
        slots: [],
      },
      "6": {
        slots: [],
      },
    },
  },
  prices: [],
  channels: [],
};

export const listOfPositiveNumber = (number: number) =>
  Array.from(Array(number).keys());
