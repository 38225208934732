import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IDialogConfirmation {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description: string;
  cancelTextButton?: string;
  submitTextButton?: string;
  handleSubmit: () => void;
}

export default function DialogConfirmation({
  open,
  setOpen,
  title,
  description,
  cancelTextButton,
  submitTextButton,
  handleSubmit,
}: IDialogConfirmation) {
  const { t } = useTranslation("general");

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          fontWeight: "600",
          letterSpacing: "1px",
          color: "#2CA58D",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions style={{ padding: "1rem" }}>
        <Button onClick={handleClose} variant="contained" color="error">
          {cancelTextButton || t("continueEditing")}
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
            handleClose();
          }}
          variant="contained"
          color="success"
        >
          {submitTextButton || t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
