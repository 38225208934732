import { z } from "zod";
import { Trans } from "react-i18next";
import { isArray, isEmpty, isEqual } from "lodash";
import { ActivityForm } from "pages/Activities";
import { toast, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ActivityPrice } from "backend/api/activities";

export const numberablePositiveString = z.string().refine(
  (value: string) => {
    return value !== "" && Number.isFinite(Number(value)) && Number(value) >= 0;
  },
  {
    message: "Value must be a positive number",
  },
);

export const numberableStrictlyPositiveString = z.string().refine(
  (value: string) => {
    return value !== "" && Number.isFinite(Number(value)) && Number(value) > 0;
  },
  {
    message: "Value must be a positive number",
  },
);

const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/;
export const dateableString = z
  .string()
  .refine((value: string) => yyyymmddRegex.test(value), {
    message: "field must be in the YYYY-MM-DD format",
  });

const yyyymmddhhmmRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
export const dateableWithTimeString = z
  .string()
  .refine((value: string) => yyyymmddhhmmRegex.test(value), {
    message: "field must be in the YYYY-MM-DD HH:mm format",
  });

const hhmmRegex = /^\d{2}:\d{2}$/;
export const timeString = z
  .string()
  .refine((value: string) => hhmmRegex.test(value), {
    message: "field must be in the HH:mm format",
  });

export function getAuthorizedConfig(
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
) {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: "",
    data: {},
  };
  return config;
}

export const pick = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));

export const omit = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));

export interface Message {
  type: "success" | "error";
  message: string;
}

export const snakeToCamelList = (inputList: any[]): any => {
  return inputList.map((item) => snakeToCamelObject(item));
};

export function snakeToCamelObject(obj: Record<string, any>): any {
  const camelObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const camelKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
    camelObj[camelKey] = value;
  }

  return camelObj;
}

export function snakeToCamelString(str: string): string {
  return str.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
}

// #TODO: explain this function in comment
export const checkOverlap = (
  start1: moment.Moment,
  end1: moment.Moment,
  start2: moment.Moment,
  end2: moment.Moment,
) => {
  return (
    start1.isBetween(start2, end2, undefined, "[)") ||
    end1.isBetween(start2, end2, undefined, "(]") ||
    start2.isBetween(start1, end1, undefined, "[)") ||
    end2.isBetween(start1, end1, undefined, "(]")
  );
};

export const applyStyleToTranslation = (
  translationElem: { key: string; params: any; link?: string },
  namespace: string,
) =>
  !translationElem.key ? (
    <br />
  ) : (
    <Trans
      i18nKey={`${namespace}:${translationElem.key}`}
      components={{
        bold: <strong />,
        urlLink: (
          <a
            style={{ fontWeight: "bold", color: "black" }}
            href={translationElem?.link}
            target="_blank"
            rel="noreferrer"
          />
        ),
        br: <br />,
      }}
      values={translationElem.params}
    />
  );

export const checkFileMaxSize = (
  file: File,
  maxSizeInBytes: number,
): boolean => {
  return file.size < maxSizeInBytes;
};

export const checkImageMinResolution = (
  file: File,
  minHeight: number,
  minWidth: number,
): Promise<boolean> => {
  return new Promise((resolve) => {
    const img: HTMLImageElement = new Image();
    const objectUrl: string = URL.createObjectURL(file);

    img.onload = function () {
      if (img.width >= minWidth && img.height >= minHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  });
};

export const isArrayEmpty = (arr: any): boolean => {
  return !isArray(arr) || isEmpty(arr);
};

export const hasActivitySelectedPrice = (activity: ActivityForm): boolean => {
  // Check if at least one price is enabled
  if (!activity) return false;
  const hasEnabledPrice =
    !isArrayEmpty(activity.prices) &&
    activity.prices.some((price) => price.status === "ENABLED");
  return hasEnabledPrice;
};

export const hasActivitySelectedTimeslot = (
  activity: ActivityForm,
): boolean => {
  if (!activity) return false;
  // Check if at least one slot is available in the hours object
  const hasAvailableSlot = Object.values(activity?.hours?.hours || {}).some(
    (day) => day.slots && day.slots.length > 0,
  );
  return hasAvailableSlot;
};

export const toastEmitter = (text: string, type: TypeOptions = "default") => {
  toast(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    type,
  });
};

export const arePropsEqual = (previousProps: any, nextProps: any) =>
  isEqual(previousProps, nextProps);

export const checkLastPriceDescription = (prices: ActivityPrice[]) => {
  if (isArrayEmpty(prices)) {
    return false; // Return false for invalid input or empty array
  }

  const lastElement = prices[prices.length - 1];
  // Check if the description matches any other element's description
  for (let i = 0; i < prices.length - 1; i++) {
    if (
      prices[i].description === lastElement.description ||
      prices[i].name === lastElement.name
    ) {
      return true; // Match found
    }
  }

  return false; // No match found
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Failed to copy text: ", error);
  }
};

export function arrayToFormattedString(
  arr: string[],
  lastSeparator: string = "and",
): string {
  if (isArrayEmpty(arr)) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) {
    return `${arr[0]} ${lastSeparator} ${arr[1]}`;
  }

  const allButLast = arr.slice(0, -1).join(", ");
  const last = arr[arr.length - 1];

  return `${allButLast}, ${lastSeparator} ${last}`;
}
