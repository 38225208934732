import React from "react";

const BowlingIcon = (props: { color: string | undefined }) => {
  const { color } = props;

  return (
    <svg
      fill={color ?? "#000"}
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502 502"
      style={{ marginLeft: "5px" }}
    >
      <g>
        <g>
          <g>
            <path
              d="M428.483,73.516C381.076,26.108,318.045,0,251,0S120.924,26.108,73.517,73.516C26.108,120.924,0,183.956,0,251
				s26.108,130.076,73.517,177.484C120.924,475.892,183.955,502,251,502s130.076-26.108,177.483-73.516
				C475.892,381.076,502,318.044,502,251S475.892,120.924,428.483,73.516z M251,482C123.626,482,20,378.374,20,251
				S123.626,20,251,20s231,103.626,231,231S378.374,482,251,482z"
            />
            <path
              d="M289.2,143c-23.82,0-43.2,19.379-43.2,43.2c0,23.821,19.38,43.2,43.2,43.2c23.821,0,43.2-19.379,43.2-43.2
				C332.4,162.379,313.02,143,289.2,143z M289.2,209.4c-12.793,0-23.2-10.408-23.2-23.2s10.407-23.2,23.2-23.2
				c12.793,0,23.2,10.407,23.2,23.2C312.4,198.993,301.993,209.4,289.2,209.4z"
            />
            <path
              d="M175.4,244.2c0-23.821-19.38-43.2-43.2-43.2S89,220.379,89,244.2c0,23.821,19.38,43.2,43.2,43.2
				C156.021,287.4,175.4,268.021,175.4,244.2z M132.2,267.4c-12.793,0-23.2-10.408-23.2-23.2s10.407-23.2,23.2-23.2
				c12.793,0,23.2,10.407,23.2,23.2C155.4,256.993,144.993,267.4,132.2,267.4z"
            />
            <path
              d="M199.5,141.7c23.82,0,43.2-19.379,43.2-43.2s-19.38-43.2-43.2-43.2s-43.2,19.379-43.2,43.2S175.68,141.7,199.5,141.7z
				 M199.5,75.3c12.793,0,23.2,10.408,23.2,23.2s-10.407,23.2-23.2,23.2c-12.793,0-23.2-10.408-23.2-23.2S186.707,75.3,199.5,75.3z"
            />
            <path
              d="M251,443c-31.486,0-62.708-7.784-90.289-22.509c-4.875-2.602-10.931-0.761-13.531,4.111
				c-2.602,4.872-0.761,10.93,4.111,13.531C181.761,454.401,216.239,463,251,463c5.522,0,10-4.477,10-10S256.522,443,251,443z"
            />
            <path
              d="M447.903,172.286c-2.052-5.128-7.873-7.619-12.999-5.57c-5.127,2.052-7.621,7.872-5.569,13
				C438.402,202.38,443,226.364,443,251c0,74.91-44.026,143.471-112.163,174.669c-5.022,2.299-7.229,8.234-4.929,13.255
				c1.681,3.672,5.307,5.839,9.098,5.839c1.392,0,2.808-0.293,4.158-0.91c36.425-16.678,67.309-43.253,89.313-76.853
				C451.062,332.511,463,292.399,463,251C463,223.807,457.921,197.324,447.903,172.286z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BowlingIcon;
